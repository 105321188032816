export class Subscription {
    constructor(consumer, channel, options = {} ) {
        this.consumer = consumer
        this.channelOptions = {
            ...options,
            channel: channel
        }
    }

    register({ connected = null, disconnected = null, received = null }) {
        return this.consumer.subscriptions.create(
            this.channelOptions,
            {
                connected: connected,
                disconnected: disconnected,
                received: received
            }
        )
    }
}
