import { Controller } from "@hotwired/stimulus";
import { FormCache } from '@js/helpers/cache_helper';
import { uid } from '@js/plugins/useful_tools';

export default class extends Controller {
    connect() {
        this.listenFiltersSubmit()

        this.filters = {}
        this.$template = $(this.element).find('template#filterPill')
    }

    listenFiltersSubmit() {
        const _this = this;
        $(window).on('submit.filters', function(_, cacheKey, count) {
            if (cacheKey !== pageKey()) { return; }

            if (count === 0) {
                // Remove pills if there is
                Object.keys(_this.filters).forEach(key => _this.removePill(key))
                // Hide line of selected filters
                $(_this.element).find('#filterLabel')
                                .addClass('d-none')
            } else {
                // Get selected filters in sessionStorage
                let cache = _this.getCache(cacheKey)

                // Display line of selected filters
                $(_this.element).find('#filterLabel')
                                .removeClass('d-none')

                // Insert pills of selected filters
                let pills = _this.insertPills(cache)

                // Remove pills which were in filters but
                // aren't anymore
                _this.removeOldPills(pills)
            }
        });
    }

    removeOldPills(pills) {
        for (const key in this.filters) {
            if (pills.includes(key)) { continue; }

            this.removePill(key)
        }
    }

    insertPills(cache) {
        let pills = []
        cache.forEach(entry => {
            let text = entry.text || entry.value
            pills.push(text)

            if (text in this.filters) { return; }

            entry.domId = uid();

            let $template = this.fillTemplate(entry);
            this.filters[text] = entry
            this.$template.before($template)
        })

        return pills
    }

    getCache(cacheKey) {
        return new FormCache(cacheKey).storage
                                      .filter( input =>
            input.name.match(/^filters/)
        )
    }

    removeFilter(event) {
        let $pill = $(event.currentTarget).parent();
        let key = Object.keys(this.filters)
                        .find(key => this.filters[key].domId == $pill.attr('id'))

        this.submitForm(key);
    }

    fillTemplate(entry) {
        let $template = this.$template.clone().contents()
        $template.find('i.fas.fa-xmark')
                 .prev()
                 .text(entry.text || entry.value)
        $template.attr('id', entry.domId)


        return $template;
    }

    submitForm(key) {
        let filter = this.filters[key]
        let $formField = $(`${filter.nodeName}[name='${filter.name}']`)

        switch (filter.nodeName) {
            case 'select':
                this.unselectOption($formField, filter);
                break;
            default:
                $formField.val('');
        }

        $formField.closest('form').trigger('requestSubmit')
    }

    unselectOption($formField, filter) {
        $formField.find(`option[value='${filter.value}']`)
                  .prop('selected', false)
                  .trigger('change')
    }

    removePill(key) {
        $(`#${this.filters[key].domId}`).remove()
        delete this.filters[key]
    }
}