import {Controller} from '@hotwired/stimulus';
import {AxiosRequest} from '@app/javascript/helpers/axios_helper';

export default class extends Controller {
    static targets = ["pagination"];

    static values = {
        scrollMethod: { type: String, default: 'post' }
    };

    loadItems(event) {
        if (this.hasPaginationTarget) {
            let url = this.paginationTarget.querySelector("a[rel='next']");

            if (url) {
                url = new URL(url.href)

                if ($(this.paginationTarget).data('turbo-target')) {
                    url.searchParams
                       .append('turboTarget',
                               $(this.paginationTarget).data('turbo-target'))
                }

                let $dom   = $(event.currentTarget)
                let sTop   = $dom.scrollTop();
                let sHeight = $dom.prop('scrollHeight');
                let cHeight = $dom.prop('clientHeight');

                if ((sHeight - (sTop + cHeight) < 100)) {
                    $(this.paginationTarget).remove()
                    new AxiosRequest(url,
                                    'get').sendTurboStream()
                }
            }
        }
    }

}