import consumer from "@js/channels/consumer"
import { Subscription } from "../subscriptions"
import { received } from "@js/helpers/card_helpers"

function getStreamNameFromMeta(name) {
    let streamMetaDom = document.querySelector(`meta[name=${name}]`)
    return streamMetaDom ? streamMetaDom.content : null
}

export class CardConsumer {
    constructor() {
        this.streamChannel = getStreamNameFromMeta('cards_stream');
        this.subscription = this.getOrCreateSubscription()
    }

    getOrCreateSubscription() {
        if (!this.streamChannel) return null;

        return this.getCardSubscriptionFromIdentifier() || this.createSubscription()
    }

    createSubscription() {
        let options = { stream: getStreamNameFromMeta('cards_stream') }
        let subscription = new Subscription(consumer, "CardsChannel", options)

        return subscription.register({ received: received })
    }

    getCardSubscriptionFromIdentifier() {
        let streamName = getStreamNameFromMeta('cards_stream');
        let identifier = JSON.stringify({channel: "CardsChannel", stream: streamName});

        return consumer.subscriptions.findAll(identifier)[0]
    }

    removeSubscription() {
        if (!this.subscription) return;

        consumer.subscriptions.remove(this.subscription)
    }
}

export class TurboCardObserver {
    constructor() {
    }

    observe() {
        let _this = this;
        // Before visit, remove subscription from CardChannel if
        // there is one active
        document.addEventListener('turbo:before-visit', (event) => {
            _this.cardConsumer.removeSubscription()
        })

        // On turbo load, add consumer subscription to card_streams
        document.addEventListener('turbo:load', (event) => {
            _this.cardConsumer = new CardConsumer();
        })
    }
}