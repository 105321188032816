import { Controller } from "@hotwired/stimulus"
import { Popover } from "bootstrap"

export default class extends Controller {
    static targets = [ 'row', 'clickableFlag' ]
    static values  = {
        withFlags: { type: Boolean, default: false }
    }

    connect() {
        if (this.withFlagsValue) {
            this.activeFlags = {
                0: ['fr', 'en', 'it', 'es', 'pt']
            }
            this.setPopover()
        }

        this.setTemplate()
    }

    toggleActive(event) {
        if (event.params.unclickable) { return };

        let flagButton = event.currentTarget
        let isoCode   = $(flagButton).data('iso-code')

        // Look for active flag button with same iso code and remove
        // active class
        $(`.border-flag-form.active[data-iso-code=${isoCode}]`).not(flagButton)
                                                               .removeClass('active')

        $(flagButton).toggleClass('active')
    }

    addRow(event) {
        let dom = this.template.clone().insertBefore($(event.currentTarget))
        this.setPopover(dom.get(0))
    }

    removeRow(event) {
        // Remove row
        $(event.currentTarget).closest('.row')
                              .remove()
    }

    setTemplate() {
        this.template = $(this.rowTargets).first().clone()
        let textarea = this.template.find('textarea')

        // Empty input
        textarea.val('')
        textarea.after("<i class='fas fa-trash color-red ms-1 pointer' data-action='click->form--appellations--block#removeRow'></i>")


        // Remove active on flags
        this.template.find('.active').removeClass('active')
    }

    setPopover(dom = this.element) {
        let unclickable_flag = $(dom).find("[data-form--appellations--block-unclickable-param='true']")

        if (!unclickable_flag) { return; }

        unclickable_flag.attr('data-bs-toggle', 'popover')
        unclickable_flag.attr('data-bs-content',
                              "Il est impossible d'ajouter ou d'enlever cette langue")

        new Popover(unclickable_flag, {
            trigger: 'hover click',
            placement: 'top'
        })

        //Set timeout on popover
        unclickable_flag.on('shown.bs.popover', function() {
            let element = this
            setTimeout(function() {
                let popover = Popover.getInstance(element)
                popover.hide();
            }, 1500);
        })
    }
}