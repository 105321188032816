export function defaultTemplate(item) {
    return item.text
}

export function country(item) {
    let country = $(item.element).data('iso-code') ? $(item.element).data('iso-code')
                                                   : item.country
    if (!country) { return defaultTemplate(item); };

    let $template = $(`<span><i class='fi fi-${country} me-1'></i> ${item.text}</span>`)
    return $template
}

export function color(item) {
    let color   = $(item.element).data('color') ? $(item.element).data('color')
                                                : item.color
    let $template = $(`<span><i class='fas fa-circle me-1' style='color: ${color}'></i> ${item.text}</span>`)

    return $template
}

export function formatItem(kind) {
    switch (kind) {
        case 'country':
            return (item) => country(item);
        case 'color':
            return (item) => color(item);
        default:
            return (item) => defaultTemplate(item);
    }
}