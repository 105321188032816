import ApplicationController from '@controllers/application_controller.js'
import { Modal } from 'bootstrap'

export default class extends ApplicationController {
    openModal(event) {
        let tagId = event.params.tagId
        this.stimulate("ModelTag#update_data", tagId,
                                               event.params.kind)
            .then( promise => {
                let modal = Modal.getOrCreateInstance($(`#modal-tagged-records-${tagId}`))
                modal.show()
            })
    }
}