import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
    static targets = [ 'inputBarcode' ]

    checkBarcodes(event) {
        let values = $(this.inputBarcodeTargets).not(event.currentTarget)
                                                .map((_, input) => $(input).val())
                                                .toArray()

        let currentVal = $(event.currentTarget).val()
        if ( values.includes(String(currentVal)) ) {
            toastr.error('Vous avez déjà attribué ce code-barre à ce produit')
            $(event.currentTarget).val('');
            return false
        } else {
            return true
        }
    }
}