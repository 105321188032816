import { Controller} from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
    connect() {
        this.observeMutations();
    }

    observeMutations() {
        let _this = this;
        this.observer = new MutationObserver(mutations => {
            mutations.forEach(function(mutation) {
                if (mutation.addedNodes.length != 0) {
                    // Open modal
                    let $modalForm = $(_this.element).find('.modal')
                    let $form = $(_this.element).find('form')
                    const modal = new Modal($modalForm)

                    // Open modal
                    modal.show()

                    $modalForm.find('.btn-close')
                              .attr('data-action', 'click->edit-modal#dismiss')
                }
            })
        })

        this.observer.observe(
            this.element, {
                childList: true
            }
        )
    }

    dismiss() {
        setTimeout(
            () => { $(this.element).empty() },
            500
        )

    }
}