export class KeyArrayStorage {
    constructor(...keys) {
        // Define an empty array for each keys
        keys.forEach( (name) => this[name] = [] );
    }

    add(key, value) {
        // Return if value is already inside array or if value is null
        if (this[key].indexOf(value) !== -1 || !value ) { return; }

        // Add value as a string to be consistent when getting value
        // from input dom or select dom
        this[key].push(String(value))
    }

    remove(key, value) {
        let index = this[key].indexOf(String(value))

        // Return if value is not inside array
        if ( index === -1 ) { return; }

        this[key].splice(index, 1)
    }
}