import TranslationController from './translations_controller.js';

export default class extends TranslationController {
    initialize() {
        super.initialize()
        this.reflexClassToRemove = "nestedField"
    }

    selectLangTargetConnected(element) {
        super.selectLangTargetConnected(element)
        if (this.allLanguagesSelected()) { this.toggleTranslationSelect(true); };
    }

    toggleTranslationSelect(disabled) {
        $('option[value="Traduction"]').not(':selected')
                                       .prop('disabled', disabled)
    }

    appendRow(event) {
        super.appendRow(event, false)
    }

    changeSelect(event) {
        // Get select dom for kind as jquery object
        let $target     = $(event.currentTarget)
        let kindChoice  = $target.val()
        let $selectLang = $(`#${$target.data('row-id')}`).find('select.select-lang')

        if ( kindChoice === 'Traduction' ) {
            // User choose Traduction, so look
            // if there was already chosen language and if so
            // add them to selectedLanguages
            this.toggleLanguages($selectLang, 'select2:select')
        } else  {
            // Remove chosen language from selectedLanguages but
            // keep selected language
            this.toggleLanguages($selectLang, 'select2:unselect')
        }

        this.stimulate("synonyms#changeSelect", event.currentTarget)
    }

    toggleOptions(event) {
        super.toggleOptions(event)
        this.toggleTranslationChoice();
    }

    toggleTranslationChoice() {
        if (this.allLanguagesSelected()) {
            this.toggleTranslationSelect(true)
        } else {
            this.toggleTranslationSelect(false)
        }
    }

    toggleOption($select_dom, value, disabled) {
        super.toggleOption($select_dom, value, disabled);

        // Remove hidden selected options if there is
        $select_dom.find(`option[value='${value}']:selected`)
                   .not(':visible')
                   .prop('selected', false)
                   .closest('select')
                   .trigger('change')

    }
}