import {Controller} from '@hotwired/stimulus';
import Tab from 'bootstrap/js/dist/tab';

export default class extends Controller {
    static values = {
        anchor: {type: String, default: ''},
    };

    connect() {
        Turbo.cache.exemptPageFromCache();
        if ( !this.anchorValue ) { return; }
        this.switchTab();
    }

    switchTab() {
        let hash = '#' + this.anchorValue;
        $('#pills-tab .active').removeClass('active')
        $('.tab-pane.active').removeClass('active')
        let $tabEl = $('#pills-tab a[href="' + hash + '"]')
        if ($tabEl && $tabEl.length !== 0) {
             $tabEl.addClass('active')
             $(`.tab-pane[aria-labelledby='${hash.slice(1)}-tab']`).addClass('active show')
        }
    }
}
