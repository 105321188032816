import { StreamActions } from "@hotwired/turbo"
import { possibleCallbacks } from "@js/helpers/callbacks_helper"

StreamActions.sweet_alert = function() {
    let templateID = $(this).attr('templateID')

    if ($(templateID).length == 0) {
        $('body').append($(this.templateContent))
    } else {
        $(templateID).replaceWith($(this.templateContent))
    }

    let preConfirmName = $(this).attr('preConfirm')
    let preConfirm = possibleCallbacks.hasOwnProperty(preConfirmName) ? possibleCallbacks[preConfirmName]
                                                                      : possibleCallbacks['default']

    let successCallbackName = $(this).attr('successCallbackName')
    let successCallback = possibleCallbacks.hasOwnProperty(successCallbackName) ? possibleCallbacks[successCallbackName]
                                                                                : possibleCallbacks['default']

    Swal.fire({
        width: $(window).width() < 800 ? "100%" : '80%',
        template: templateID,
        showCloseButton: true,
        allowOutsideClick: false,
        preConfirm: preConfirm
    }).then(successCallback)
}