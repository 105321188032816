export function uid() {
    return Math.floor(( 1+ Math.random() ) * 0x10000)
               .toString(16)
               .substring(1)
};

export function partition(array, fn) {
    return array.reduce( function (accumulator, value, i, array) {
        accumulator[fn(value, i, array) ? 0 : 1].push(value)
        return accumulator;
    }, [[], []] );
}