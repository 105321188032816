import {Controller} from '@hotwired/stimulus';
import { AxiosRequest } from '../helpers/axios_helper';

export default class extends Controller {
    static values = {
        currentBeverages: { type: Number, default: 1 },
        path: { type: String, default: '/' }
    }

    async switch(event) {
        event.preventDefault();
        this.switchAccountAndRedirect();
    }

    switchAccountAndRedirect() {
        new AxiosRequest('/switch_beverages', 'post', { beverages_id: this.currentBeveragesValue })
            .sendHtml()
            .then(
                () => {
                    window.location.href = this.pathValue;
                }
            );
    }
}
