import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['message'];

    connect() {
        $(() => {
            // Remove all toasts before caching
            document.addEventListener('turbo:before-cache', () => {
                toastr.remove();
            });


            this.messageTargets.forEach(element => {
                this.toaster_notification(element.dataset);
            });
        })
    }

    toaster_notification(dataset) {
        switch (dataset.type) {
            case 'danger':
            case 'alert':
            case 'error':
                toastr.error(dataset.msg);
                break;
            case 'warning':
                toastr.warning(dataset.msg);
                break;
            case 'success':
            case 'notice':
                toastr.success(dataset.msg);
                break;
        }
    }
}
