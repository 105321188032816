export function measureText(text, font) {
    // See: https://www.w3docs.com/snippets/javascript/how-to-calculate-text-width-with-javascript.html
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');

    // Set font
    context.font = font
    let metrics  = context.measureText(text)

    return metrics.width
}

export function pageKey() {
    return window.location
                 .pathname
                 .replace(/\/$/, '')
                 .trim()
}