import ApplicationController from '@controllers/application_controller.js'

export default class extends ApplicationController {
    static targets = ["form"]

    connect() {
        super.connect();
        $(this.formTarget).on('submit', function(e) {
            $(this).find("[name='disabled']")
                   .prop('disabled', true)
        });
    }
}