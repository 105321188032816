import { partition } from "@js/plugins/useful_tools";

export function received(data) {
    if (data.cableReady) {
        // First separate remove operations from other
        const condition = operation => (operation.operation === 'remove') ||
                                       (operation.rebroadcast)
        const[ sureOperations, unsureOperations ] = partition(data.operations,
                                                              condition)

        // Then do the remove operation, and check if user is currently searching for
        // other operation
        CableReady.perform(sureOperations);
        performUnsureOperations(this, unsureOperations);
    }
}

function performUnsureOperations(subscription, operations) {
    let query    = $('#search').val()
    let lastPage = $("[id^=page-]").last()
                                  .attr('id')
    let filters = JSON.stringify(getFilters())

    if (!lastPage || (!query && ( filters === '{}' )) ) {
        CableReady.perform(operations)
    } else {
        lastPage = parseInt(lastPage.split('-')[1]);

        operations.forEach(operation => {
            subscription.perform('rebroadcast', {
                sgid: operation.sgid,
                lastPage: lastPage,
                query: query,
                html: operation.html,
                filters: filters
            })
        })
    }
}

function getFilters () {
    let $filters = $(".modal [name^='filters']").serializeArray()

    if ($filters.length === 0) { return {}; }

    jsonFilters = {}
    $filters.forEach(filter => {
        // Get filter name
        let name = filter.name.match(/\[(.+?)\]/)[1]

        if (jsonFilters.hasOwnProperty(name)) {
            jsonFilters[name].push(filter.value)
        } else {
            jsonFilters[name] = [ filter.value ]
        }
    })

    return jsonFilters
}