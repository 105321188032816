export class TabCache {
    constructor(key) {
        this.key = key;
    }

    store() {
        sessionStorage.setItem(this.key, JSON.stringify([...this.storage]));
    }
}

export class FormCache extends TabCache {
    constructor(key) {
        super(key)
        this.storage = sessionStorage.hasOwnProperty(key) ? JSON.parse(sessionStorage.getItem(key))
                                                          : [];
    }

    set(key, value) {
        let _cache = this.storage.find(field => field.name === key)

        if (_cache) {
            _cache['value'] = value
        } else {
            this.storage.push( { name: key, value: value} )
        }

        this.store()
    }

    cacheForm(form) {
        let cached_form = form.serializeArray().filter( input => input.name != 'authenticity_token' );

        // Get text if different from value
        cached_form.forEach( entry => {
            let dom = $(`[name='${entry.name}']`)
            entry.nodeName = dom.prop('nodeName').toLowerCase();
            if ( !dom.is('select') ) {
                return;
            }

            let option = dom.find(`option[value='${entry.value}']`)
            entry.text = option.text()
        } )

        sessionStorage.setItem(this.key, JSON.stringify(cached_form))
    }
}

export class SetCache extends FormCache {
    constructor(key) {
        super(key)
        this.storage = new Set(this.storage)
    }

    add(item) {
        this.storage.add(item)
    }

    remove(item) {
        this.storage.delete(item)
    }
}