import Autocomplete from 'stimulus-autocomplete';

export default class extends Autocomplete {
    static values = {
        scrollable: { type: Boolean, default: false },
        selected: { type: Object, default: {} }
    };

    connect() {
        super.connect()
        let elt = this.element
        $(elt).on('focusout', () => {
            $(elt).find('input#autocomplete')
                  .val(null);
        })
    }

    resultsTargetConnected(element) {
        $(element).on('scroll', () => {
            this.handleSelectedResults();
        })
    }

    open() {
        super.open();
        if (this.scrollableValue){
            this.resultsTarget.dispatchEvent(new CustomEvent('scroll'));
        }

        this.handleSelectedResults();
    }

    handleSelectedResults() {
        if (this.selectedValue.length !== 0 )
        {
            setTimeout(() => { this.disableSelected() }, 100)
        }
    }

    disableSelected() {
        const keys = Object.keys(this.selectedValue)
        $(this.resultsTarget).find("li").each((_, item) => {
            for (const key of keys) {
                let ids = $(item).attr(key)
                if (!ids || ids.length === 0) { continue; }

                // Look if selected values and ids has common entries
                let selectedSet = new Set(this.selectedValue[key])
                let idsSet = new Set(JSON.parse(ids))

                if (selectedSet.intersection(idsSet).size !== 0) {
                    $(item).addClass('disabled')
                    break;
                } else {
                    $(item).removeClass('disabled')
                }

            }
        })
    }
}
