import {Controller} from '@hotwired/stimulus';
import JsBarcode from "jsbarcode";

export default class extends Controller {
    static targets = ['imageDOM']

    imageDOMTargetConnected(element) {
        JsBarcode(this.imageDOMTarget,
            $(element).data('ean'), {
                format: "ean13",
                width: 1.5,
                height: 50,
                background: 'transparent'
        });
    }
}