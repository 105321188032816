import { partition } from "@js/plugins/useful_tools";
import { AxiosRequest } from "./axios_helper";

function prepareForm(serializedArray) {
    let form = new FormData();
    serializedArray.forEach( (choice) => {
        for ( const [key, value] of Object.entries(choice) ) {
            if ( key === 'name' ) { continue; }

            form.append(`${choice.name}[${key}]`, value);
        }
    });

    return form;
}

function createRowObserver($row, resolve) {
    let isExistingProduct = $row.find('input[name$="[_destroy]"]').length > 0
    let observer = new MutationObserver( (mutations, observer) => {
        for (const record of mutations) {
            // If existing product, check that _destroy input is set to 1
            if (isExistingProduct) {
                if (record.target.name === 'products[][_destroy]' && record.target.value === "1") {
                    observer.disconnect();
                    resolve()
                }
            } else {
                for (const removedNode of record.removedNodes) {
                    if (removedNode.id === $row.attr('id')) {
                        observer.disconnect()
                        resolve()
                    }
                }
            }
        }
    })

    let target  =  isExistingProduct ? $row.get(0)
                                     : $('#product_form').get(0)
    let options = isExistingProduct ? { attributes: true, attributesFilter: [ 'value' ], subtree: true }
                                    : { childList: true, subtree: true }

    observer.observe(target,
                     options);
}

function createPromise($row) {
    const promise = new Promise((resolve) => {
        let observer = createRowObserver($row, resolve)
    })

    return promise;
}

export var possibleCallbacks = {
    'errorBarcodesCheck': errorBarcodesCheck,
    'errorBarcodesSuccess': errorBarcodesSuccess,
    'default': preConfirmDefault
}

export async function errorBarcodesCheck() {
    let values = $('.swal-check-val').map((_, dom) => $(dom).val())
                                     .toArray();

    if (values.some(x => !x)) {
        Swal.showValidationMessage('Vous devez faire un choix pour chaque erreur !');
        return false;
    } else {
        // Return choices of user for each barcode error
        return $('[name^="choices"]').serializeArray()
    }
}

export async function errorBarcodesSuccess(result) {
    if (result.isConfirmed) {
        // Parse JSON values of user selected choices
        let choices = result.value.map( (choice) => {
            return Object.assign({},
                                 { name: choice.name },
                                 JSON.parse(choice.value));
        });

        // First partition choices per action acting on current millesime and actions acting
        // on others millesimes
        const condition =  choice => (choice.action === 'removeToCurrent') || (choice.action === 'keepForCurrent');
        let [ actOnCurrent, actOnOthers ] = partition(choices, condition)

        let promises = []
        // Delete current rows
        if (actOnCurrent.length > 0) {

            for (const choice of actOnCurrent) {
                let $row = $(`.row:contains(${choice.barcode})`)

                if (choice.action === 'removeToCurrent') {
                    const promise = createPromise($row);
                    $row.find('.fa-trash')
                        .trigger('click')

                    promises.push(promise)
                } else {
                    $row.removeClass('d-none');
                    $row.find('input[name="products[][_destroy]"]')
                        .val('0')
                }

            }

            // Wait that all rows are deleted before continuing
            await Promise.all(promises)
        }

        // Post actions which act on others millesimes
        if (actOnOthers.length > 0 ) {
            let form = prepareForm(actOnOthers);
            await new AxiosRequest('/barcodes/handle_errors',
                                   'post',
                                   form,
                                   { "Content-Type": 'multipart/form-data' }).sendHtml();
        }

        let $form = $('#product_form')
        if ($form.find('[name^="products[]"]').length > 0) {
            $('#product_form').trigger('submit')
        } else {
            $('#product_form').siblings('.modal-header')
                              .find('btn.btn-close')
                              .trigger('click')
        }

    } else {
        return;
    }
}

export async function preConfirmDefault() {
    return true;
}