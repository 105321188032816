import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    toggleOptions(event) {
        let selector = event.params.toggleSelector

        // Get selected values
        let selectedValues = $(event.currentTarget).val()

        // If selected values are empty enable all options
        if (selectedValues.length === 0) {
            $(`option[data-toggle-${selector}]`).attr('disabled', false);
            return;
        }

        // Disabled options
        $(`option[data-toggle-${selector}]`).each(function() {
            if (selectedValues.includes(String($(this).attr(`data-toggle-${selector}`)))) {
                $(this).attr('disabled', false)
            } else {
                $(this).attr('disabled', true)
            }

        })
    }
}