// Jquery
import initJquery from "./plugins/jquery";
initJquery();

// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "../components"

// plugins
import initToastr from './plugins/toastr';
import "./config"
import "./channels"

initToastr();

import notifyUser from "./plugins/notify";
notifyUser();

// Select2
import select2 from 'select2';
select2($)

// Cache key
import { pageKey } from "./helpers/text_helper";
window.pageKey = pageKey

window.addEventListener('popstate', (event) => {
    // CAREFULLL: If user click on return with browser,
    // we reload the page to reload js
    if (event.state) {
        window.location.reload()
    }
})

//Sweet alert
import { initSweetAlert } from "./plugins/sweet_alert";
initSweetAlert();

// Enable tooltips
import { Tooltip } from "bootstrap";
$("[data-bs-toggle='tooltip']").each((_, element) => {
    return new Tooltip(element);
});

// Autosize textarea
import autosize from 'autosize'
window.autosize = autosize;

$(() => {
    autosize($('textarea'));
})

// Custom turbo actions
import 'turbo_actions/sweet_alert';