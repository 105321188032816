import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ 'uploader' ]

    connect() {
        let _this = this;
        $(this.uploaderTarget).on('change', function() {
            if ($(this).hasClass('image-input')) {
                _this.previewImage(this.files[0])
            }

        })
    }

    uploadFile(event) {
        this.uploaderTarget.click()
    }

    removeImage(event) {
        $(this.element).find('.img-preview').empty()
        $(this.element).find('.img-remover').empty()
    }

    previewImage(imgFile) {
        let reader = new FileReader();
        let $element = $(this.element);
        reader.onload = function(e) {

            $element.find('.img-preview')
                    .empty()
                    .append($("<img>",
                              { class: 'thumbnail thumbnail--medium position-relative z-1',
                                src: e.target.result,
                                alt: imgFile.name,
                                width: 100, height: 100 }))

            $element.find('.img-remover')
                    .append($("<span></span>",
                              { text: 'Supprimer',
                                class: 'color-red pointer',
                                "data-action": 'click->form--image-uploader#removeImage'  }))
        }

        reader.readAsDataURL(imgFile)
    }
}