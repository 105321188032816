import {Controller} from '@hotwired/stimulus';
import { FormCache } from '../helpers/cache_helper';

export default class extends Controller {
    static values = {
        page: String,
        resetable: { type: Boolean, default: false }
    }

    static targets = ['backBtn',
                      'input',
                      'form']

    connect() {
        // Set Form Cache
        this.cacheKey = pageKey()
        this.formCache = new FormCache(this.cacheKey)
        this.checkButtons();
        this.checkForms()

        // FIXME: Maybe there is a best solution to check if a user is reloaded
        // a page which is resetable (i.e. a page which has filters cache on it )
        var _this = this;
        $(window).on('load', function() {
            if ((_this.cacheKey == sessionStorage['lastVisited']) && (_this.resetableValue)) {
                sessionStorage.clear();
            }
        })
        $(window).on('unload turbo:visit', function(event) {
            let pathname = event.detail ? new URL(event.detail.url).pathname
                                        : _this.cacheKey
            sessionStorage.setItem('lastVisited', pathname)
        })

    }

    setPostBtnCache(event) {
        // Prevent submit for button
        event.preventDefault()

        let btn = event.currentTarget;
        this.set_form_cache(btn.form, $(btn).data('cacheKey'))

        // Submit form
        btn.form.submit()
    }

    set_form_cache(form, cacheFor, scope = null) {
        let formCache = new FormCache(cacheFor)

        formCache.storage.forEach(field => {
            // If we decide to scope the field name we have to replace
            // name by:
            // *) if it's in form attr_1[attr_2] => scope[attr_1][attr_2]
            // *) if it's in form attr_1 => scope[attr_1]
            let name = scope ? `${scope}[` + field.name.replace(/(\w)(\[|$)/, '$1]$2')
                             : field.name

            let input = $(`<input type=hidden name='${name}' value='${field.value}'>`)
            $(form).append(input)
        })
    }

    resetFilters(event) {
        if (!event.ctrlKey) {
            sessionStorage.clear()
        }

    }

    checkForms() {
        const _this = this;
        $(this.formTargets).on('submit', function(e) {
            _this.set_form_cache(this, $(this).data('cache-for'), 'cache')
        })
    }

    checkButtons() {
        const _this = this;
        $(this.backBtnTargets).on('click', function(event) {
            event.preventDefault()
            _this.set_form_cache(this.form, $(this).data('cache-for'))
            this.form.submit()
        })
    }
}