import ApplicationController from '@controllers/application_controller.js'

export default class extends ApplicationController {
    static values = {
        reflexData: { type: Object, default: {} }
    }

    static targets = ['clickable']

    toggle(event) {
        if (this.clickableTargets.includes(event.target)) return;

        let $cardBody = $(this.element).find('.card-body')
                                       .addBack('.card-body')
        let $collapsable = $cardBody.find('.collapsable:first')

        if ($collapsable.length === 1) {
            $collapsable.remove()
        } else {
            const reflexData = this.reflexDataValue
            this.stimulate(`Collapse#toggle`,
                           ...reflexData.args)
                .then(promise => {
                    let html = promise.payload.html
                    $cardBody.append(html)
                })
        }
    }

}