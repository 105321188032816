import ApplicationController from '@controllers/application_controller.js'
import { Popover } from "bootstrap"

export default class extends ApplicationController {
    static targets = ['unclickable']
    static values  = {
        prefix: { type: String, default: '' },
        preventFill: { type: Array, default: [] },
        duplicate: { type: Boolean, default: false }
    }

    unclickableTargetConnected(element) {
        this.setPopover(element)
    }

    toggleActive(event) {
        let flagButton = event.currentTarget

        if ($(event.currentTarget).data('form--flags-target') === 'unclickable') { return; }

        let isoCode   = $(flagButton).data('iso-code')
        // Look for active flag button with same iso code and remove
        // active class
        if (!this.duplicateValue) {
            $(this.element).find(`.border-flag-form.active[data-iso-code=${isoCode}]`)
                           .not(flagButton)
                           .removeClass('active')
        }

        if ($(flagButton).hasClass('active')) {
            // We want to remove from translations
            $(flagButton).removeClass('active')
            this.duplicateValue ? this.checkFlag(flagButton, false)
                                : this.setHiddenValue(isoCode, '')
        } else {
            $(flagButton).addClass('active')

            let currentRow = $(`#${event.params.rowId}`)
            let inputDom   = currentRow.find('textarea')
            this.duplicateValue ? this.checkFlag(flagButton, true)
                                : this.setHiddenValue(isoCode, inputDom.val())
        }

    }

    resetHiddenValues(event) {
        let rowId = $(event.currentTarget).data('remove-target')
        this.setActiveValues(rowId, '')
        this.stimulate("addComponent#remove", event.currentTarget)
    }

    setHiddenValues(event) {
        let inputDom = event.currentTarget
        this.setActiveValues(event.params.rowId,
                             $(inputDom).val())
    }

    checkFlag(flagButton, checked) {
        let checkbox = $(flagButton).prev("input[type='checkbox']")
        if (checkbox.length === 0) { return; }

        checkbox.prop('checked', checked)
    }

    setActiveValues(rowId, value) {
        let currentRow = $(`#${rowId}`)

        let activeFlags = this.getActiveFlags(currentRow)

        let _this = this;
        activeFlags.forEach(isoCode => {
            _this.setHiddenValue(isoCode, value)
        })
    }

    setHiddenValue(isoCode, value) {
        $(`#${this.prefixValue}_${isoCode}_translation`).val(value)
    }

    setPopover(dom) {
        let unclickable_flag = $(dom)

        if (!unclickable_flag) { return; }

        unclickable_flag.attr('data-bs-toggle', 'popover')
        unclickable_flag.attr('data-bs-content',
                              "Il est impossible d'ajouter ou d'enlever cette langue")

        new Popover(unclickable_flag, {
            trigger: 'hover click',
            placement: 'top'
        })

        //Set timeout on popover
        unclickable_flag.on('shown.bs.popover', function() {
            let element = this
            setTimeout(function() {
                let popover = Popover.getInstance(element)
                popover.hide();
            }, 1500);
        })
    }

    getActiveFlags(row) {
        let activeFlags = row.find('.border-flag-form.active')
        this.preventFillValue.forEach(isoCode => {
            activeFlags = activeFlags.not(`[data-iso-code='${isoCode}']`)
        });

        return activeFlags.map((_, dom) => $(dom).data('iso-code'))
                          .get()
    }
}