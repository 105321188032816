import ApplicationController from '@controllers/application_controller.js'
import Pickr from '@simonwep/pickr';

export default class extends ApplicationController {
    static values = {
        color: { type: String, default: '#eaeaea' }
    }
    static targets = ['colorPicker', 'inputColor']

    colorPickerTargetConnected(element) {
        this.createPicker();
        this.createEventsForPicker();
    }

    createPicker() {
        let _this = this;
        this.pickr = Pickr.create({
            el: this.colorPickerTarget,
            theme: 'nano',
            appClass: 'ms-2',
            default: _this.colorValue,
            components: {
                preview: true,
                opacity: true,
                hue: true,

                interaction: {
                    hex: true,
                    input: true,
                    clear: false,
                    save: true,
                },
            },
        })
    }

    createEventsForPicker() {
        this.pickr.on('save', (color, _instance) => {
            this.inputColorTarget.value = color.toHEXA().toString();
            this.pickr.hide();
        });
    }
}