import ApplicationController from '@controllers/application_controller.js'
import JsBarcode from "jsbarcode";

export default class extends ApplicationController {
    static targets = ['input', 'imageDOM', 'locker']
    static outlets = [ 'form--products' ]

    connect() {
        super.connect()
        let $input = $(this.inputTarget)
        if (!$input.val()) {
            setTimeout(() => {
                $input.trigger('focus')
            }, 500)
        }

        this.setBarcode()
    }

    setBarcode(event) {
        let $input = $(this.inputTarget)

        if ($input.val().length !== 13) {
            $input.removeClass('valid')
            $input.addClass('invalid')
            $(this.imageDOMTarget).empty()
            this.unlockRow();
            return;
        }

        try {

            let isValid = true;
            if (event) { isValid = this.formProductsOutlet.checkBarcodes(event); }

            if (isValid) {
                this.prefillAttributes($input)
                JsBarcode(this.imageDOMTarget, $input.val(), {
                    format: "ean13",
                    width: 1.5,
                    height: 50,
                    background: 'transparent'
                });
                $input.addClass('valid');
                $input.removeClass('invalid');
            }
        } catch (exception) {
            console.log(exception)
            let cevent = new CustomEvent("notify",
                                        { detail: {
                                            message: "Ce code barre n'est pas valide avec le format EAN13"
                                        }})
            $input.removeClass('valid')
            $input.addClass('invalid')
            $(this.imageDOMTarget).empty()

            document.dispatchEvent(cevent)
        }
    }

    prefillAttributes($input, productId) {
        let ean = $input.val()
        this.stimulate('Form::InputBarcode#prefillAttributes',
                       this.inputTarget)
            .then(promise => {
                let attributes = JSON.parse(promise.payload)
                // Return if no attributes
                if ( attributes.pass ) { return; }

                // Else prefill inputs
                let $rowDom = $input.closest('.row')

                if ( attributes.lock ) { this.lockRow(ean); }

                for (const [attribute, value] of Object.entries(attributes)) {
                    let $inputToFill = $rowDom.find((`[name="products[][${attribute}]"`))
                    $inputToFill.val(value)

                    if (attributes.lock) {
                        $inputToFill.is('select') ? this.disableSelect($inputToFill)
                                                  : $inputToFill.attr('readonly', true)
                    }


                }
            })
    }

    disableSelect($select) {
        $select.css('pointer-events', 'none')
        $select.parent().addClass('select-readonly')
    }

    lockRow(ean) {
        $(this.lockerTarget).removeClass('d-none')
        toastr.warning(`Le code-barre ${ean} est déjà utilisé. Cliquez sur le cadenas
                        pour pouvoir éditer les attributs. Cela changera les attributs pour
                        tous les produits associé à ce code-barre ! `,
                        'Attributs verrouillés',
                        {
                            timeOut: 2000,
                            extendedTimeOut: 0,
                            closeButton: true,
                            toastClass: 'warning-gray'
                        })

    }

    unlockRow(event) {
        let $row = $(this.lockerTarget).closest('.row')
        // Reenable select
        $row.find('select').filter( function() {
            return $(this).css('pointer-events') === 'none';
        }).css('pointer-events', '')

        // Put first options in selects
        $row.find('select').prop('selectedIndex', 0)


        $row.find('.select-readonly').removeClass('select-readonly')

        // Reenable inputs
        $row.find('input[readonly]').attr('readonly', false)

        // Put unit to 1
        $row.find('input#unit').val(1)

        // Remove lock
        $(this.lockerTarget).addClass('d-none')
    }
}