import { Controller } from "@hotwired/stimulus";
import {Modal} from 'bootstrap';
import { FormCache } from '@js/helpers/cache_helper';

export default class extends Controller {
    static values = {
        filtered: { type: Boolean, default: false }
    }

    static targets = [ 'modal' ]

    connect() {
        this.form = this.element;
        this.cacheKey = pageKey()

        if (this.filteredValue) {
            this.modal = new Modal(this.modalTarget);
            this.updateFilterCount(true)
        }
        this.listenSubmit()
    }

    listenSubmit() {
        const _this = this;
        $(this.form).on('submit', function() {
            new FormCache(_this.cacheKey).cacheForm($(this))
            let count = 0;
            if ( _this.filteredValue ) {
                count = _this.updateFilterCount()
                _this.modal.hide()
            }
            $(window).trigger("submit.filters", [ _this.cacheKey, count])
        });
    }

    openFilterModal() {
        this.modal.show()
    }

    updateFilterCount(init = false) {
        const count = this.filterCount()

        if (count !== 0) {
            $('#filter-count').text(count)
            if (init) {
                $(() => $(window).trigger("submit.filters", [ this.cacheKey, count]))
            }
        } else {
            $('#filter-count').empty()
        }

        return count;
    }

    filterCount() {
        const formData = new FormData(this.form);

        let reject = ['authenticity_token', 'query']
        return Array.from(formData.entries())
                    .filter(input => !reject.includes(input[0]) && input[1] !== '')
                    .length
    }
}