import {Controller} from '@hotwired/stimulus';
import {AxiosRequest} from '@app/javascript/helpers/axios_helper';

export default class extends Controller {
    static targets = ["pagination"];
    static values = {
        scrollMethod: { type: String, default: 'post' }
    };

    scroll() {
        let url = '#';

        if (this.hasPaginationTarget)
        {
            url  = this.paginationTarget.querySelector("a[rel='next']");
            if (url)
            {
                url = url.href;

                const el = $('[data-controller="scroll--infinite"]');
                const wBottom  = $(window).scrollTop() + $(window).height();
                const elBottom = el.offset().top + el.height();

                if (wBottom + 1000 > elBottom){
                    $(this.paginationTarget).remove();
                    this.loadMore(url);
                }
            }
        }
    }

    loadMore(url){
        let lastHeader = $('#last_header');
        let lastNestedHeader = $('#last_nested_header');

        var params = new FormData();

        if (lastHeader.length > 0) {
            params.append('last_header', lastHeader.data('last-header'));
            lastHeader.remove();
        }

        if (lastNestedHeader.length > 0) {
            params.append('last_nested_header', lastNestedHeader.data('last-nested-header'));
            lastNestedHeader.remove();
        }

        var _this = this;
        $('.search_form').each( function(_, form) {
            _this.appendFormToFormData(params, form)
        });

        new AxiosRequest(url,
                         this.scrollMethodValue,
                         params).sendTurboStream();
    }

    appendFormToFormData(formData, form) {
        let dataToAppend = $(form).serializeArray();
        dataToAppend.forEach(field => {
            formData.append(field.name, field.value)
        });
    }
}
