import ApplicationController from '@controllers/application_controller.js';

export default class extends ApplicationController {
    static targets = ['selectLang',
                      'row']
    static values = {
        languages: { type: Array, default: [] },
    }

    initialize() {
        this.selectedLanguages = new Set()
        this.reflexClassToRemove = "addComponent"
    }

    selectLangTargetConnected(element) {
        if ($(element).val().length > 0) {
            // Target has selected language so toggle options
            this.toggleLanguages($(element), 'select2:select')
        } else {
            // New element so toggle already selected languages
            for (const isoCode of this.selectedLanguages) {
                this.toggleOption($(element), isoCode, true)
            }
        }

    }

    appendRow(event, notify = true) {
        if (this.allLanguagesSelected() && notify) {
            // If there is only langs, we can't add new row
            this.notify('Toutes les langues sont déjà sélectionnées')
            return;
        }

        if (this.hasEmptyRows()) return;

        this.stimulate("addComponent#append", event.currentTarget)
    }

    hasEmptyRows() {
        for (const row of this.rowTargets) {
            // Find input text
            let $textarea = $(row).find('textarea').not('.select2-search__field')
            let input  = $textarea.length > 0 ? $textarea.val()
                                              : 'first' // If no input found no row are present so fake text
            let kind   = $(row).find("[id^='synonym-type'").val()

            // Get select values
            let values = $(row).find('select.check-select:visible').val() || ['first'] // Same if no values, no row are present
            // Check if there is empty input or values
            if (input.trim().length === 0 || ( (values.length === 0) && (kind == 'Traduction'))) {
                this.notify('Remplissez votre dernière ligne avant de pouvoir en ajouter une autre')
                return true;
            }
        };

        return false
    }

    resetOptions(event) {
        // We click on trash icon so we want to clear selected langs
        let $target = $(event.currentTarget)
        let $select = $(`#${$target.data('remove-target')}`).find('select.select-lang')
        this.toggleLanguages($select, 'select2:unselect')
        this.stimulate(`${this.reflexClassToRemove}#remove`, event.currentTarget)
    }

    toggleOptions(event) {
        let selected_id = event.id
        let select_doms = $(this.selectLangTargets).not(event.currentTarget)

        if (event.selectType == 'select2:select') {
            this.selectedLanguages.add(selected_id)
            this.toggleOption(select_doms, selected_id, true)
        } else {
            this.selectedLanguages.delete(selected_id)
            this.toggleOption(select_doms, selected_id, false)
        }
    }

    toggleOption($select_dom, value, disabled) {
        $select_dom.find(`option[value='${value}']`)
                   .attr('disabled', disabled)
    }

    notify(message){
        let cevent = new CustomEvent("notify",
                                        { detail: {
                                            message: message
                                        }})
        document.dispatchEvent(cevent)
    }

    allLanguagesSelected() {
        return this.selectedLanguages.size == this.languagesValue.length;
    }

    toggleLanguages($selectDom, selectType) {
        // Enabled or disabled languages for all select
        // which are not the one which is specified (selectDom)
        $selectDom.val()
                  .map( value => this.toggleOptions({
            currentTarget: $selectDom.get(0),
            selectType: selectType,
            id: value
        }))
    }
}